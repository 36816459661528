// index.js
import { all } from 'redux-saga/effects';

import exampleSagas from './example/saga';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* rootSaga() {
    const appSagas = [...exampleSagas];

    yield all([...appSagas]);
}

export default rootSaga;
