import type CommonStyles from '../types/commonStyles.type';
import colors from './colors';

const commonStyles: CommonStyles = {
    boxShadow: `0 0 0.75rem 0 rgba(${colors.accents[0].rgb},0.15)`,
    scrollbar: {
        default: {
            track: {
                background: 'transparent',
                border: colors.secondary.tints[1].hex
            },
            thumb: {
                background: colors.secondary.tints[1].hex,
                hover: {
                    background: colors.secondary.tints[2].hex
                }
            }
        },
        secondary: {
            track: {
                background: colors.tertiary.hex,
                border: 'none'
            },
            thumb: {
                background: colors.secondary.tints[0].hex,
                hover: {
                    background: colors.secondary.tints[1].hex
                }
            }
        }
    },
    brandPixel: '1px'
};

export default commonStyles;
