import { createSlice } from '@reduxjs/toolkit';
import type { ExampleState } from './types';

export const initialState: ExampleState = {
    result: {},
    loading: false,
    error: false
};

const exampleSlice = createSlice({
    name: 'example',
    initialState,
    reducers: {
        fetch(state) {
            state.loading = true;
            state.error = false;
            state.result = {};
        },
        fetchSuccess(state, action) {
            state.result = action.payload.result;
            state.loading = false;
        },
        fetchFailure(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        }
    }
});

export const { name, actions, reducer } = exampleSlice;
