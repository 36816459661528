import { Client, Query, Op } from 'contensis-delivery-api';
import type { PagedList, ContensisQueryOrderBy, IExpression } from 'contensis-core-api';
import type { Entry } from 'contensis-delivery-api/lib/models';

interface ContensisOptions {
    preview?: boolean;
    fetchSurrogateKeys?: boolean;
    projectId?: string;
}

interface ClientOptions {
    versionStatus: 'latest' | 'published';
    fetchSurrogateKeys: boolean;
    surrogateKeys: string[];
    projectId: string;
}

interface SearchOptions {
    pageSize?: number;
    pageIndex?: number;
    orderBy?: string | string[] | ContensisQueryOrderBy;
    fields?: string[];
    linkDepth?: number;
}

interface Contensis {
    client: Client;
    surrogateKeys: string[];
    search: (
        expression: IExpression | IExpression[],
        options?: SearchOptions
    ) => Promise<PagedList<Entry>>;
    fetch: <DataType extends Record<string, unknown>>(
        fetchFnc: (
            client: Client,
            search: Contensis['search']
        ) => Promise<DataType & { error?: boolean }>
    ) => Promise<{ data?: DataType; error: boolean }>;
}

const createClient = (clientOptions: ClientOptions): Client => {
    const defaultHeaders = {};

    if (clientOptions.fetchSurrogateKeys) {
        defaultHeaders['x-require-surrogate-key'] = true;
    }

    return Client.create({
        rootUrl: 'https://api-solent-uni.cloud.contensis.com',
        accessToken: 'QVI8Xg1x6zb4IDJjpFiyI0SFvxoSOt5Gi5BJOpwBWhs0PU3m',
        projectId: clientOptions.projectId,
        language: 'en-GB',
        versionStatus: clientOptions.versionStatus,
        responseHandler: {
            200: res => {
                if (clientOptions.fetchSurrogateKeys) {
                    const surrogateKeyHeader = res.headers.get('surrogate-key');

                    if (surrogateKeyHeader) {
                        clientOptions.surrogateKeys.push(surrogateKeyHeader);
                    }
                }
            }
        },
        defaultHeaders
    });
};

const contensis = (options?: ContensisOptions): Contensis => {
    const surrogateKeys: string[] = [];
    const versionStatus = options?.preview
        ? 'latest'
        : process.env.APP_ENV === 'staging' || process.env.APP_ENV === 'production'
        ? 'published'
        : 'latest';

    const client = createClient({
        versionStatus,
        fetchSurrogateKeys: options?.fetchSurrogateKeys || false,
        surrogateKeys: surrogateKeys,
        projectId: options?.projectId || 'website'
    });

    const search: Contensis['search'] = (expression, options) => {
        const expressions = Array.isArray(expression) ? expression : [expression];
        const query = new Query(Op.equalTo('sys.versionStatus', versionStatus), ...expressions);

        if (options) {
            if (options.pageSize) query.pageSize = options.pageSize;
            if (options.pageIndex) query.pageIndex = options.pageIndex;
            if (options.fields) query.fields = options.fields;
            if (options.orderBy) query.orderBy = options.orderBy;
        }

        return client.entries.search(query, options?.linkDepth || 0);
    };

    const fetch: Contensis['fetch'] = async fetchFunc => {
        try {
            const result = await fetchFunc(client, search);

            if ('error' in result && result.error) {
                return { error: true };
            } else {
                return { data: result, error: false };
            }
        } catch (error) {
            const statusNotFound = 404;

            if (error.status !== statusNotFound) {
                console.error(error);
            }

            return {
                error: true
            };
        }
    };

    return {
        client,
        surrogateKeys,
        search,
        fetch
    };
};

export default contensis;
