import { createSlice } from '@reduxjs/toolkit';
import type { CourseCompareState } from './types';

export const initialState: CourseCompareState = {
    courses: []
};

const courseCompareSlice = createSlice({
    name: 'courseCompare',
    initialState,
    reducers: {
        fetch(state) {
            const courses = window.localStorage.getItem('courses');

            if (courses) {
                state.courses = JSON.parse(courses);
            }
        },
        add(state, action) {
            state.courses = [...state.courses, action.payload.course];
        },
        remove(state, action) {
            state.courses = state.courses.filter(course => course.id !== action.payload.course.id);
        },
        clear(state) {
            state.courses = [];
        },
        save(state) {
            window.localStorage.setItem('courses', JSON.stringify(state.courses));
        }
    }
});

export const { name, actions, reducer } = courseCompareSlice;
