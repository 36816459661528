import { createSlice } from '@reduxjs/toolkit';
import type { InPageNavigationState } from './types';

export const initialState: InPageNavigationState = {
    defaultOpen: true
};

const inPageNavigationSlice = createSlice({
    name: 'inPageNavigation',
    initialState,
    reducers: {
        fetch(state) {
            const defaultOpen = window.localStorage.getItem('inPageNavDefaultOpen');

            if (defaultOpen) {
                state.defaultOpen = JSON.parse(defaultOpen);
            }
        },
        toggleIsDefaultOpen(state) {
            state.defaultOpen = !state.defaultOpen;
        },
        save(state) {
            window.localStorage.setItem('inPageNavDefaultOpen', JSON.stringify(state.defaultOpen));
        }
    }
});

export const { name, actions, reducer } = inPageNavigationSlice;
