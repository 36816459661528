import type Gradients from '../types/gradients.type';

const gradients: Gradients = {
    primary: '#F58F1E 0%, #F4831F 13%, #F26820 24%, #F04722 39%, #EE2C23 62%, #E30613 100%',
    primaryOpposite: '#E30613 0%, #EE2C23 38%, #F04722 61%, #F26820 76%, #F4831F 87%, #F58F1E 100%',
    secondary: '#ED1C24 0%, #DB1E2F 15%, #AA254A 37%, #5E2E77 67%, #2B3594 100%',
    secondaryOpposite: '#2B3594 0%, #5E2E77 33%, #AA254A 63%, #DB1E2F 85%, #ED1C24 100%',
    secondaryShort: '#db1e2f 0%, #aa254a 75%, #5e2e77 100%',
    accent: {
        one: '#9F519A 0%, #97589F 34%, #8269AD 56%, #677FBF 71%, #4B95D1 80%, #36A6DF 89%, #2EADE4 100%',
        two: '#2EAFE5 0%, #2DB0DC 20.96%, #2CB1C6 40.96%, #2AB4A9 60.48%, #28B58B 80%, #27B67C 100%',
        twoOpposite:
            '#27B67C 0%, #28B58B 20.96%, #2AB4A9 40.96%, #2CB1C6 60.48%, #2DB0DC 80%, #2EAFE5 100%',
        three: '#85C443 0%, #83C048 9.91%, #7DB753 19.25%, #74AA60 28.37%, #69996D 37.67%, #5B8479 47.52%, #4D6D84 58.3%, #3E558D 70.38%, #313F92 84.16%, #2B3594 100%',
        threeOpposite:
            '#2B3594 0%, #313F92 9.91%, #3E558D 19.25%, #4D6D84 28.37%, #5B8479 37.67%, #69996D 47.52%, #74AA60 58.3%, #7DB753 70.38%, #83C048 84.16%, #85C443 100%',
        four: '#CE2036 0%, #8A295D 100%',
        fourOpposite: '#8A295D 0%, #CE2036 100%',
        five: '#FFFFFF 0%, #F9FAFA 35.26%, #EEF2F2 64.74%, #E8EDED 100%',
        six: '#000216 0%, #FF0000 100%'
    }
};

export default gradients;
