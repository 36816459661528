import { useState, useEffect } from 'react';

const useReducedMotion = (): boolean => {
    const [isMotionReduced, setIsMotionReduced] = useState(false);

    useEffect(() => {
        const motionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

        const handleQueryChange = () => {
            setIsMotionReduced(motionMediaQuery.matches);
        };

        handleQueryChange();

        if (typeof motionMediaQuery === 'object') {
            motionMediaQuery.addEventListener('change', handleQueryChange);
            return () => motionMediaQuery.removeEventListener('change', handleQueryChange);
        }
    }, []);

    return isMotionReduced;
};

export default useReducedMotion;
