import { configureStore, getDefaultMiddleware, EnhancedStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import createReducer from './rootReducer';
import rootSagas from './rootSagas';

export default function configureAppStore(initialState = {}): EnhancedStore {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

    // sagaMiddleware: Makes redux-sagas work
    const middlewares = [sagaMiddleware];

    const store = configureStore({
        reducer: createReducer(),
        middleware: [...getDefaultMiddleware(), ...middlewares],
        preloadedState: initialState,
        devTools: process.env.NODE_ENV !== 'production'
    });

    sagaMiddleware.run(rootSagas);

    return store;
}
