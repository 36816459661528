import '../src/polyfills';

import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Error from 'next/error';
import { useRouter } from 'next/router';
import App from 'next/app';
import { Globals } from '@react-spring/web';
import { Op, OrderBy } from 'contensis-delivery-api';

import type { NextPageWithLayout } from 'next';
import type { AppPropsWithLayout } from 'next/app';

import useReducedMotion from 'Common/hooks/useReducedMotion';
import contensis from 'Common/api/contensis';
import { excludeMetadata } from 'Common/util/contensis/api';

import lightTheme from 'Theme/lightTheme';
import GlobalStyles from 'Global/index';
import configureStore from 'Redux/configureStore';

import 'Public/fonts/bliss/bliss.css';
import 'Public/fonts/bilo/bilo.css';

const store = configureStore({});

const MyApp = ({ Component, pageProps, initialData }: AppPropsWithLayout): React.ReactNode => {
    const getLayout = Component.getLayout ?? ((page: NextPageWithLayout) => page);
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const prefersReducedMotion = useReducedMotion();

    if (!Component) {
        return <Error statusCode={404} />;
    }

    useEffect(() => {
        const handleStart = () => {
            setIsLoading(true);
        };

        const handleStop = () => {
            setIsLoading(false);
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [router]);

    useEffect(() => {
        Globals.assign({
            skipAnimation: prefersReducedMotion
        });
    }, [prefersReducedMotion]);

    return (
        <Provider store={store}>
            <ThemeProvider theme={lightTheme}>
                <GlobalStyles />
                {getLayout(<Component {...pageProps} isLoading={isLoading} />, {
                    isLoading,
                    initialData
                })}
            </ThemeProvider>
        </Provider>
    );
};

MyApp.getInitialProps = async appContext => {
    const contensisClientWebsite = contensis().client;
    const fetchPromises = [
        contensisClientWebsite.entries.list({
            contentTypeId: 'siteNavigation',
            linkDepth: 4,
            pageOptions: {
                pageSize: 1
            },
            fields: ['-sys']
        }),
        contensisClientWebsite.entries.list({
            contentTypeId: 'siteFooter',
            linkDepth: 4,
            pageOptions: {
                pageSize: 1
            },
            fields: ['-sys']
        }),
        contensisClientWebsite.entries.list({
            contentTypeId: 'siteClearingBanner',
            linkDepth: 2,
            pageOptions: {
                pageSize: 1
            },
            fields: ['-sys']
        })
    ];

    const { search: searchGlobal } = contensis({ projectId: 'global' });
    const siteAlertQuery = [
        Op.equalTo('sys.contentTypeId', 'globalSiteAlert'),
        Op.equalTo('isActive', true),
        Op.in('project', 'Solent', 'All')
    ];

    const appProps = await App.getInitialProps(appContext);

    try {
        const [resSiteNavigation, resSiteFooter, resClearingBanner] = await Promise.all(
            fetchPromises
        );
        const resSiteAlert = await searchGlobal(siteAlertQuery, {
            pageSize: 1,
            fields: ['*', ...excludeMetadata, 'sys.version.modified'],
            orderBy: OrderBy.desc('sys.version.modified')
        });

        return {
            ...appProps,
            initialData: {
                siteNavigation: resSiteNavigation.items[0],
                siteFooter: resSiteFooter.items[0],
                clearingBanner: resClearingBanner.items[0],
                siteAlert: resSiteAlert.items[0]
            }
        };
    } catch {
        return {
            ...appProps,
            initialData: {
                siteNavigation: null,
                siteFooter: null,
                clearingBanner: null,
                siteAlert: null
            }
        };
    }
};

export default MyApp;
