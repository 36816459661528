import { createSlice } from '@reduxjs/toolkit';
import type { SiteAlertState } from './types';

export const initialState: SiteAlertState = {
    isExpanded: false
};

const siteAlertSlice = createSlice({
    name: 'siteAlert',
    initialState,
    reducers: {
        toggleIsExpanded(state) {
            state.isExpanded = !state.isExpanded;
        }
    }
});

export const { name, actions, reducer } = siteAlertSlice;
