import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html {
        /* scroll-behavior: smooth !important; */
        scroll-padding-top: 3.75rem; // height of sticky header for offset
    }

    body {
        padding: 0;
        margin: 0;
        box-sizing: border-box;

        * {
            box-sizing: inherit;
        }
    }

    li {
        margin: 0.25rem 0;
    }

    // Force hide the cookie policy close button
    #ccc #ccc-notify-dismiss {
        display: none !important;
    }
`;
