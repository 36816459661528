import { createGlobalStyle, css } from 'styled-components';

export const paragraph = css`
    font-size: 1.125rem;
    line-height: 1.55;
    font-weight: 300;
    margin: 1rem 0;

    h1 + & {
        margin-top: 0;
    }

    h2 + & {
        margin-top: -1rem;
    }

    h3 + &,
    h4 + &,
    h5 + &,
    h6 + & {
        margin-top: 0;
    }

    @media ${({ theme }) => theme.breakpoints.large.media} {
        font-size: 1.25rem;
    }
`;

export const anchor = css`
    ${({ theme }) => css`
        color: ${theme.colors.interactive.hex};
        font-weight: 300;
        letter-spacing: 0;
        line-height: inherit;
        text-underline-offset: 0.125em;
        text-decoration-thickness: 0.0625rem;

        &:hover {
            text-decoration-thickness: 0.125rem;
        }
    `}
`;

export const heading1 = css`
    ${({ theme }) => css`
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 2.75rem;

        @media ${theme.breakpoints.xlarge.media} {
            font-size: 3.5rem;
            line-height: 1.4;
        }
    `}
`;

export const heading2 = css`
    ${({ theme }) => css`
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.5rem;
        margin: 0.5em 0 1.5rem;

        @media ${theme.breakpoints.large.media} {
            font-size: 2.5rem;
            line-height: 1.4;
        }
    `}
`;

export const heading3 = css`
    ${({ theme }) => css`
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 2rem;
        margin: 0.6em 0 0.25rem;

        @media ${theme.breakpoints.large.media} {
            font-size: 2.25rem;
            line-height: 1.4;
        }
    `}
`;

export const heading4 = css`
    ${({ theme }) => css`
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 2.25rem;
        margin: 0.5em 0 0.25rem;

        @media ${theme.breakpoints.large.media} {
            font-size: 2rem;
            line-height: 1.4;
        }
    `}
`;

export const heading5 = css`
    ${({ theme }) => css`
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        margin: 0.5em 0 0.25rem;

        @media ${theme.breakpoints.large.media} {
            font-size: 1.75rem;
            line-height: 1.4;
        }
    `}
`;

export const heading6 = css`
    ${({ theme }) => css`
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.75rem;
        margin: 0.5em 0 0.25rem;

        @media ${theme.breakpoints.large.media} {
            font-size: 1.5rem;
            line-height: 1.4;
        }
    `}
`;

export default createGlobalStyle`
    ${({ theme }) => css`
        body,
        input,
        label,
        button {
            font-family: ${theme.fonts.primary};
            font-size: 1rem;
            color: ${theme.colors.text.hex};
            font-weight: 300;
            text-underline-offset: 0.125em;
            text-decoration-thickness: 0.0625em;
            line-height: 1.4;

            @media ${theme.breakpoints.large.media} {
                font-size: 1.25rem;
            }
        }

        label {
            font-weight: 600;
        }

        p {
            ${paragraph}
        }

        a {
            ${anchor}
        }

        h1 {
            ${heading1}
        }

        h2 {
            ${heading2}
        }

        h3 {
            ${heading3}
        }

        h4 {
            ${heading4}
        }

        h5 {
            ${heading5}
        }

        h6 {
            ${heading6}
        }
    `}
`;
