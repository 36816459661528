import { DefaultTheme } from 'styled-components';
import breakpoints from './breakpoints';
import colors from './colors';
import gradients from './gradients';
import modules from './modules';
import components from './components';
import elements from './elements';
import commonStyles from './commonStyles';
import fonts from './fonts';

const lightTheme: DefaultTheme = {
    name: 'light',
    spacing: 0.5,
    breakpoints,
    colors,
    gradients,
    modules,
    components,
    elements,
    commonStyles,
    fonts
};

export default lightTheme;
