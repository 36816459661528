import { takeLatest, put, call } from 'redux-saga/effects';
import { actions } from './slice';
import contensis from 'API/contensis';
import { SagaIterator } from '@redux-saga/core';

export function* fetchRootNode(): SagaIterator {
    try {
        const result = yield call(() =>
            contensis().client.nodes.getRoot({
                entryLinkDepth: 2,
                entryFields: ['*']
            })
        );

        yield put(actions.fetchSuccess({ result }));
    } catch (error) {
        yield put(actions.fetchFailure({ error }));
    }
}

export default [takeLatest(actions.fetch.type, fetchRootNode)];
