import { combineReducers, Reducer } from '@reduxjs/toolkit';

import { reducer as exampleReducer } from './example/slice';
import { reducer as courseCompareReducer } from './courseCompare/slice';
import { reducer as inPageNavigationReducer } from './inPageNavigation/slice';
import { reducer as siteAlertReducer } from './siteAlert//slice';

export default function createReducer(): Reducer {
    const rootReducer = combineReducers({
        example: exampleReducer,
        courseCompare: courseCompareReducer,
        inPageNavigation: inPageNavigationReducer,
        siteAlert: siteAlertReducer
    });

    return rootReducer;
}
