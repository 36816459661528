import React from 'react';

import Base from './Base';
import Typography from './Typography';

const GlobalStyles: React.FC = () => (
    <>
        <Base />
        <Typography />
    </>
);

export default GlobalStyles;
