export const excludeMetadata = [
    '-sys.metadata',
    '-sys.availableLangauges',
    '-sys.workflow',
    '-sys.properties',
    '-sys.owner',
    '-sys.versionStatus',
    '-sys.version.published',
    '-sys.version.modified',
    '-sys.version.versionNo'
];
